export const FINANCIAL_ADVISER_PENSION_TRANSFERS_SPECIALIST_ID = 635;
export const FINANCIAL_ADVISER_PENSION_TRANSFERS_NON_SPECIALIST_ID = 557;

export const FINANCIAL_ADVISER_MORTGAGES = 'financial_adviser_advice_areas_services_mortgages';
export const FINANCIAL_ADVISER_DEBT_MANAGEMENT = 'financial_adviser_advice_areas_services_debt_management';
export const FINANCIAL_ADVISER_EQUITY_RELEASE = 'financial_adviser_advice_areas_services_equity_release';
export const FINANCIAL_ADVISER_PENSION_TRANSFERS_SPECIALIST = 'financial_adviser_advice_areas_services_pension_transfers_specialist';
export const FINANCIAL_ADVISER_PENSION_TRANSFERS_NON_SPECIALIST = 'financial_adviser_advice_areas_services_occupational_pension_transfers';
export const FINANCIAL_ADVISER_INSURACE_PROTECTION = 'financial_adviser_advice_areas_services_insurance_protection';

export const FAKE_FINANCIAL_ADVISER_PENSION_TRANSFERS_DROPDOWN_ID = 'fake_financial_adviser_pension_transfers_dropdown_id';
