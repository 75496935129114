export const FA_ADVICE_AREAS = {
    MORTGAGES_ID: 392,
    INVESTMENT_AND_PENSIONS_ID: 397,
    EQUITY_RELEASE_ID: 394,
    INSURANCE_AND_PROTECTION_ID: 391
};

export const FA_WEALTH = {
    SHOW_ALL_LEVELS: {
        LABEL: 'Show all levels',
        ID: 0,
        GA_LABEL: null,
        WEALTH_LEVEL_IDS: null,
    },
    UNDER_50000: {
        LABEL: 'Under £50,000',
        ID: 402,
        GA_LABEL: 'Under £50,000',
        WEALTH_LEVEL_IDS: '402',
    },
    OVER_50000: {
        LABEL: 'Over £50,000',
        ID: 403,
        GA_LABEL: 'Over £50,000',
        WEALTH_LEVEL_IDS: '402,403',
    },
    OVER_100000: {
        LABEL: 'Over £100,000',
        ID: 404,
        GA_LABEL: 'Over £100,000',
        WEALTH_LEVEL_IDS: '402,403,404',
    },
    OVER_250000: {
        LABEL: 'Over £250,000',
        ID: 405,
        GA_LABEL: 'Over £250,000',
        WEALTH_LEVEL_IDS: '402,403,404,405',
    },
    OVER_500000: {
        LABEL: 'Over £500,000',
        ID: 406,
        GA_LABEL: 'Over £500,000',
        WEALTH_LEVEL_IDS: '402,403,404,405,406',
    },
};

export const MA_WEALTH = {
    SHOW_ALL_LEVELS: {
        LABEL: 'Show all levels',
        ID: 0,
        GA_LABEL: null,
        WEALTH_LEVEL_IDS: null,
    },
    ANY_LEVEL: {
        LABEL: 'Under £50,000',
        ID: 408,
        GA_LABEL: 'Under £50,000',
        WEALTH_LEVEL_IDS: '408',
    },
    OVER_50000: {
        LABEL: 'Over £50,000',
        ID: 587,
        GA_LABEL: 'Over £50,000',
        WEALTH_LEVEL_IDS: '408,587',
    },
    OVER_100000: {
        LABEL: 'Over £100,000',
        ID: 409,
        GA_LABEL: 'Over £100,000',
        WEALTH_LEVEL_IDS: '408,587,409',
    },
    OVER_250000: {
        LABEL: 'Over £250,000',
        ID: 410,
        GA_LABEL: 'Over £250,000',
        WEALTH_LEVEL_IDS: '408,587,409,410',
    },
    OVER_500000: {
        LABEL: 'Over £500,000',
        ID: 411,
        GA_LABEL: 'Over £500,000',
        WEALTH_LEVEL_IDS: '408,587,409,410,411',
    },
};

export const PROMOTIONAL_OFFER = {
    LEGAL_ADVISER_FREE_1_HOUR: {
        GKA: 'legal_adviser_promotion_one_hour_free',
        ID: 82,
    },
    FA_FREE_30_MINUTE: {
        ID: 415,
        GKA: 'financial_adviser_promotional_offer_free_30_minute',
    },
    FA_FREE_1_HOUR: {
        ID: 416,
        GKA: 'financial_adviser_promotional_offer_free_one_hour',
    },
    FA_DISCOUNT_100: {
        ID: 417,
        GKA: 'financial_adviser_promotional_offer_up_to_100_discount',
    },
    FA_DISCOUNT_200: {
        ID: 418,
        GKA: 'financial_adviser_promotional_offer_up_to_200_discount',
    },
    PAV: {
        ID: 624,
        GKA: 'pav'
    },
};

export const FA_PROMOTION_IDS = [
    PROMOTIONAL_OFFER.FA_FREE_30_MINUTE.ID,
    PROMOTIONAL_OFFER.FA_FREE_1_HOUR.ID,
    PROMOTIONAL_OFFER.FA_DISCOUNT_100.ID,
    PROMOTIONAL_OFFER.FA_DISCOUNT_200.ID,
];

export const FEE_TYPES = {
    INVESTMENT_AND_PENSIONS: 'investmentandpension',
    FINANCIAL_PLANNING: 'investmentandpensionwithplanning',
    MORTGAGE: 'mortgage',
};
