import { PROFESSIONAL_TYPE } from '../__constants__';

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;


export function createCookie(name, value, milliseconds) {
    let expires = '';
    if (milliseconds) {
        const date = new Date();
        date.setTime(date.getTime() + milliseconds);
        expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value}${expires};path=/`;
}

export function getCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function deleteCookie(name) {
    createCookie(name, '', -1);
}

export function isOutsideViewport(containerId, threshold = 0) {
    const container = document.getElementById(containerId);

    if (container) {
        return window.innerHeight < (container.offsetHeight + threshold);
    }

    return false;
}

export function formatPlural(count, single, multiple) {
    return count === 1 ? single : multiple;
}

export function getResultsThresholdCount(total, existingOffset, threshold) {
    var offset = existingOffset ? existingOffset + threshold : 5;

    if ((total - offset) < threshold) {
        return Math.max(0, total - offset);
    }

    return threshold;
}

export function calculateReviewsOffset(reviewsOffset) {
    if (reviewsOffset === 0) return 5;
    return 20;
}

export function getApplicationEnvironment() {
    return createReplaceVariable('NEXT_PUBLIC_APPLICATION_ENV_REPLACEME');
}

export function shouldGARun() {
    const shouldGA = createReplaceVariable('NEXT_PUBLIC_GOOGLE_ANALYTICS_RUN_REPLACEME');
    return shouldGA === true || shouldGA === 'true';
}

export function isProductionEnv() {
    return getApplicationEnvironment() === 'prod';
}

export function formatFirstCharUpper(word) {
    if (typeof word !== 'string') return '';

    return word.charAt(0).toUpperCase() + word.slice(1);
}

export function isMortgageAdviser(type) {
    return type === 'mortgage_limited' || type === 'mortgage_adviser';
}

export function isProtectionAdviser(type) {
    return type === PROFESSIONAL_TYPE.PROTECTION_LIMITED || type === PROFESSIONAL_TYPE.PROTECTION_WHOLE_OF_MARKET;
}

export function isEquityReleaseAdviser(type) {
    return type === PROFESSIONAL_TYPE.EQUITY_RELEASE_LIMITED || type === PROFESSIONAL_TYPE.EQUITY_RELEASE_WHOLE_OF_MARKET;
}

export function getFormattedContactNumber(tel) {
    if (!tel) return '';

    const value = tel.toString().trim().replace(/^\+/, '');

    if (value.match(/[^0-9]/)) {
        return tel;
    }

    let country;
    let city;
    let number;

    switch (value.length) {
        case 10: // +1PPP####### -> C (PPP) ###-####
            country = 1;
            city = value.slice(0, 3);
            number = value.slice(3);
            break;
        case 11: // +CPPP####### -> CCC (PP) ###-####
            country = '';
            city = value.slice(0, 4);
            number = value.slice(4);
            break;
        case 12: // +CCCPP####### -> CCC (PP) ###-####
            country = value.slice(0, 3);
            city = value.slice(3, 5);
            number = value.slice(5);
            break;
        default:
            return tel;
    }

    if (country === 1) {
        country = '';
    }

    number = number.slice(0, 3) + ' ' + number.slice(3);

    return (country + city + ' ' + number).trim();
}

export function createReplaceVariable(variableName) {

    const variableMapping = {
        NEXT_PUBLIC_API_URL_REPLACEME: process.env.NEXT_PUBLIC_API_URL_REPLACEME,
        NEXT_PUBLIC_CONSUMER_DUTY_REPORT_URL_REPLACEME: process.env.NEXT_PUBLIC_CONSUMER_DUTY_REPORT_URL_REPLACEME,
        NEXT_PUBLIC_SPARTA_URL_REPLACEME: process.env.NEXT_PUBLIC_SPARTA_URL_REPLACEME,
        NEXT_PUBLIC_ACCOUNTS_URL_REPLACEME: process.env.NEXT_PUBLIC_ACCOUNTS_URL_REPLACEME,
        NEXT_PUBLIC_PROFESSIONAL_URL_REPLACEME: process.env.NEXT_PUBLIC_PROFESSIONAL_URL_REPLACEME,
        NEXT_PUBLIC_GOOGLE_MAPS_KEY_REPLACEME: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY_REPLACEME,
        NEXT_PUBLIC_GOOGLE_MAPS_MAP_ID_REPLACEME: process.env.NEXT_PUBLIC_GOOGLE_MAPS_MAP_ID_REPLACEME,
        NEXT_PUBLIC_GOOGLE_TAG_MANAGER_KEY_REPLACEME: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_KEY_REPLACEME,
        NEXT_PUBLIC_APPLICATION_ENV_REPLACEME: process.env.NEXT_PUBLIC_APPLICATION_ENV_REPLACEME,
        NEXT_PUBLIC_GOOGLE_ANALYTICS_RUN_REPLACEME: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_RUN_REPLACEME,
        NEXT_PUBLIC_CONSUMER_TYPE_REPLACEME: process.env.NEXT_PUBLIC_CONSUMER_TYPE_REPLACEME,
        API_URL_REPLACEME_INTERNAL: process.env.API_URL_REPLACEME_INTERNAL,
        SPARTA_URL_REPLACEME_INTERNAL: process.env.SPARTA_URL_REPLACEME_INTERNAL,
        PROFESSIONAL_URL_REPLACEME_INTERNAL: process.env.PROFESSIONAL_URL_REPLACEME_INTERNAL,
        NEXT_PUBLIC_GOOGLE_ANALYTICS_KEY_REPLACEME: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_KEY_REPLACEME,
        NEXT_PUBLIC_LINKEDIN_PIXEL_REPLACEME: process.env.NEXT_PUBLIC_LINKEDIN_PIXEL_REPLACEME,
        NEXT_PUBLIC_GTAG_KEY_REPLACEME: process.env.NEXT_PUBLIC_GTAG_KEY_REPLACEME,
        STATIC_REGENERATION_TIME: process.env.STATIC_REGENERATION_TIME,
    }

    return variableMapping[variableName];
}

export function trimLeadingZeros(number) {
    return number.replace(/^0+/, '')
}

export function isIE11() {
    return !!window.MSInputMethodContext && !!document.documentMode;
}

export function getRatingFormat(amount, decimalsNumber) {
    return parseFloat(amount).toFixed(decimalsNumber);
}

export function featureToggle(name, values = [false]) {
    if (!name || !window.sessionStorage) return null;

    if (window.sessionStorage.getItem(name) === null) {
        const randomInt = (min, max) =>
            Math.floor(Math.random() * (max - min + 1)) + min;
        const featureValue = values[randomInt(0, values.length - 1)];

        window.sessionStorage.setItem(name, JSON.stringify(featureValue));

        return featureValue;
    }

    return JSON.parse(window.sessionStorage.getItem(name));
}

export function createInputId(gka = null) {
    return Boolean(gka) ? `${gka}-input` : null;
}

export const getNumberFromString = (string) => {
    return string.match(/\d/g).join("")
}
