export const PAGE = {
    HOME: 'HomePage',
    EMAIL_PREFERENCES: 'UpdateEmailPreferencesPage',
    PROFILE: '/[type]/[location]/[professionalPermalink]',
    FIRM: '/firm/[firmPermalink]',
    FIRM_ADVISERS: 'FirmAdvisers',
    FIRM_ABOUT: 'FirmAbout',
    PROFESSIONAL: 'ProfessionalPage',
    SEARCH: '/[type]/[location]',
    REGION: 'RegionPage',
    FEE_REPORT: 'FeeReportPage',
    WIDGET_REVIEWS: 'WidgetReviewsPage',
    ENQUIRY: 'EnquiryPage',
    WRITE_REVIEW: 'WriteReviewPage',
    LEGAL: 'LegalPage',
    FOLLOW_UP: 'FollowUp',
    ERROR: 'ErrorPage',
    NETWORK: 'NetworkPage',
    NETWORK_ABOUT: 'NetworkAbout',
    NETWORK_ADVISERS: 'NetworkAdvisers',
};
