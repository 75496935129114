export const ENQUIRY_FORM_VALUES = 'vf_enquiry_form_values';
export const ENQUIRY_SUBMIT_VALUES = 'vf_enquiry_submit_values';
export const REFERRAL_TOKEN = 'vf_referral_token';
export const REFERRAL_CODE = 'vf_referral_code';
export const CLAIMED_VOUCHER = 'vf_claimed_voucher';
export const VOUCHED_MODAL_LAST_VIEWED = 'vf_voucher_modal_last_viewed';
export const DO_NOT_SHOW_COVID_MESSAGE = 'vf_do_not_show_covid_message';

export const PERSISTED_STATE_SEARCH_FILTERS = 'vf_search_filters_state';
export const PERSISTED_STATE_SEARCH_PAGE = 'vf_search_page_state';
export const PERSISTED_STATE_SEARCH_LISTINGS = 'vf_search_page_listings';

export const PERSISTED_FIRM_REVIEW_FILTERS = 'vf_firm_review_filters';

export const LAST_EVENT_FIRED_FOR_DASHBOARD_STATS = 'vf_last_event_fired_for_dashboard_stats';
export const LAST_PROFILE_VIEWED_PROFESSIONAL_ID = 'vf_last_profile_viewed_professional_id';
export const GA_SESSION_ID = 'vf_ga_session_id';
export const ONE_MIN_NAV_AWAY_TRACKING_ENABLED = 'vf_one_min_nav_away_tracking_enabled';
export const INVITE_TOKEN_REVIEW_TYPE = 'vf_review_type_invite_token';
export const INVITE_TOKEN_FI_TYPE = 'vf_fi_type_invite_token';

export const LAST_EVENT_FIRED_FOR_FIRM_STATS = 'vf_last_event_fired_for_firm_stats';
export const LAST_PROFILE_VIEWED_FIRM_ID = 'vf_last_profile_viewed_firm_id';
export const GA_FIRM_SESSION_ID = 'vf_ga_firm_session_id';

export const LAST_EVENT_FIRED_FOR_NETWORK_STATS = 'vf_last_event_fired_for_network_stats';
export const LAST_PROFILE_VIEWED_NETWORK_ID = 'vf_last_profile_viewed_network_id';
export const GA_NETWORK_SESSION_ID = 'vf_ga_network_session_id';
