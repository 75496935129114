import React from "react";
import { createTheme } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

export const accessibilityBgColor = "#000";

export const vfColoursOld = {
  darkBlue: "#106582",
  blue: "#0099bc",
  lightBlue: "#6bbdd2",
  skyBlue: "#d9edf7",
  faintBlue: "#f5f8fc",
  green: "#6aba00",
  pink: "#d35b83",
  lightPink: "#ff8cb2",
  darkGrey: "#4a4a4a",
  midGrey: "#414141",
  grey: "#888987",
  lightGrey: "#f5f8fc",
  white: "#fff",
};

export const vfColours = {
  navyBlue: "#022D4F",
  mintGreen: "#4BC1CA",
  brightBlue: "#4282F3",
  grassGreen: "#1FAF83",
  cherryRed: "#E43B58",
  darkWarmGrey: "#474444",
  paleMintGreen: "#DBF3F5",
  paleBlue: "#DDE9FF",
  paleGrassGreen: "#E2F5EF",
  palePink: "#F3D3C7",
  midWarmGrey: "#C1B8B3",
  paleWarmGrey: "#EEEBE9",
  white: "#fff",
};

export const palette = {
  primary: {
    light: vfColours.white,
    main: vfColours.navyBlue,
    dark: vfColours.darkWarmGrey,
    contrastText: vfColours.white,
  },
  secondary: {
    light: vfColours.paleMintGreen,
    main: vfColours.mintGreen,
    dark: vfColours.grassGreen,
    contrastText: vfColours.paleGrassGreen,
  },
  tertiary: {
    light: vfColours.paleBlue,
    main: vfColours.brightBlue,
    contrastText: vfColours.paleWarmGrey,
    dark: vfColours.midWarmGrey,
  },
  error: {
    light: vfColours.palePink,
    main: vfColours.cherryRed,
    contrastText: vfColours.white,
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
};

export function createThemeOverride(overrides = {}) {
  return createTheme({
    palette,
    overrides: {
      ...overrides,
      MuiTypography: {
        h1: {
          fontWeight: 700,
        },
        h2: {
          fontWeight: 700,
          paddingBottom: 5,
        },
        h3: {
          fontWeight: 700,
        },
        h4: {
          color: palette.primary.main,
          fontWeight: 700,
        },
        h5: {
          fontWeight: 700,
        },
        h6: {
          fontWeight: 700,
        },
        body1: {
          fontSize: '1.1rem'
        }
      },
      MuiInputBase: {
        root: {
          backgroundColor: palette.primary.contrastText,
        },
      },
      MuiSelect: {
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
      MuiCircularProgress: {
        colorSecondary: {
          color: palette.secondary.dark,
        },
      },
      MuiChip: {
        colorSecondary: {
          backgroundColor: palette.secondary.dark,
        },
      },
      MuiFormControlLabel: {
        label: {
          fontSize: 14,
        },
      },
      MuiInputBase: {
        root: {
          borderRadius: "2rem",
          backgroundColor: "#fff",
          color: palette.primary.main,
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: "2rem",
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.secondary.main,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.secondary.main,
          },
        },
        input: {
          padding: 18.5,
        },

      },
      MuiMenu: {
        paper: {
          borderRadius: "1em",
          padding: 0,
          "& .MuiListItem-root": {
            fontWeight: 700,
            color: palette.primary.main,
            borderBottom: `1px solid ${palette.tertiary.contrastText}`,
            "&:hover": {
              color: palette.tertiary.main,
              backgroundColor: palette.tertiary.light,
            },
            "&.Mui-selected": {
              color: palette.tertiary.main,
              backgroundColor: palette.tertiary.light,
              "&:hover": {
                color: palette.tertiary.main,
                backgroundColor: palette.tertiary.light,
              },
            },
          },
          
        },
      },
      MuiButton: {
        root: {
          borderRadius: "2rem",
          fontWeight: 700,
          letterSpacing: '1px'
        },
        sizeSmall: {
          paddingLeft: 16,
          paddingRight: 16,
        },
        contained: {
          boxShadow: 'none'
        }
      },
      MuiSkeleton: {
        root: {
          transform: "none",
        },
      },
    },
    typography: {
      fontSize: 14,

      // Use the system font instead of the default Roboto font.
      fontFamily: ['"Nunito"', "-apple-system"].join(","),
    },
  });
}

const style = () => ({
  "@global": {
    a: {
      color: palette.tertiary.main,
    },
    p: {
      color: palette.primary.main,
    },
    div: {
      color: palette.primary.main,
    },
    li: {
      color: palette.primary.main,
    },
    label: {
      color: palette.primary.main,
    },
    " .slick-dots li": {
      margin: 0,
    },
    ".slick-dots li button:before": {
      color: "#106582",
      opacity: 1,
      fontSize: 12,
    },
    ".slick-dots li.slick-active button:before": {
      color: "#4BC1CA",
    },
  },
});

function Theme(props) {
  return (
    <MuiThemeProvider theme={createThemeOverride(props.overrides)}>
      {props.children}
    </MuiThemeProvider>
  );
}

export function exportThemedComponent(Component) {
  return ({ overrides, ...props }) => {
    return (
      <MuiThemeProvider theme={createThemeOverride(overrides)}>
        <Component {...props} />
      </MuiThemeProvider>
    );
  };
}

export default withStyles(style)(Theme);
