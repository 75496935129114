import {
    SET_APP_REDIRECT_STATUS,
} from '../actions/types';

const INITIAL_STATE = {
    status: null,
};

const redirectReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_APP_REDIRECT_STATUS:
            return { ...state, status: action.payload };
        default:
            return state;
    }
};

export default redirectReducer;
