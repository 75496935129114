import _ from 'lodash';
import { URL_COMPONENTS } from '../__constants__';

function hasTrailingSlash(pathname) {
    return pathname !== "/" && (/\/$/).test(pathname)
}

function hasCapitalisedCity(pathname) {
    const urlComponents = pathname.split('/');
    const regexString = new RegExp(`(${_.values(URL_COMPONENTS).join('|')})`);

    if (urlComponents[1] && urlComponents[2] && regexString.test(urlComponents[1])) {
        return (/[A-Z]/).test(urlComponents[2])
    }

    return false;
}

function hasOldAdviserPermalink(pathname) {
    const matches = pathname.match(/^\/(financial-advisor-ifa|ifa-financial-advisor-mortgage|mortgage-advisor|solicitor|accountant|protection-advisor|equity-release-advisor)\/([\w']+[\w-%]*)\/([1-9][0-9]*)(.*)/);

    return matches && matches.length;
}

function hasFirmAmpersand(pathname) {
    const urlComponents = pathname.split('/');
    return (urlComponents[1] === 'firm' && urlComponents[2] && urlComponents[2].indexOf('&') !== -1);
}

function replaceCapitalisedCity(pathname) {
    const urlComponents = pathname.split('/');
    const regexString = new RegExp(`(${_.values(URL_COMPONENTS).join('|')})`);

    if (urlComponents[1] && urlComponents[2] && regexString.test(urlComponents[1])) {
        urlComponents[2] = urlComponents[2].toLowerCase();
    }

    return urlComponents.join("/");
}

/** Dirty dirty google search hack because it is illogical **/
export function getPathRedirect () {
    let pathname = window.location.pathname;
    let pathnameChanged = false;

    if (hasFirmAmpersand(pathname)) {
        pathname = pathname.replace('&', 'and');
        pathnameChanged = true;
    }

    if (hasTrailingSlash(pathname)) {
        pathname = pathname.slice(0, -1);
        pathnameChanged = true;
    }

    if (hasOldAdviserPermalink(pathname)) {
        pathname = pathname.replace(/\/([1-9][0-9]*-)/, '/0$1');
        pathnameChanged = true;
    }

    if (pathnameChanged) {
        const qs = window.location.search;

        if (qs.length) {
            pathname = pathname + qs;
        }
    }

    return pathnameChanged ? pathname : null;
}

export function getAnalyticsSafeURL(url) {
    return _.isString(url) ? replaceCapitalisedCity(url.replace(/\/$/, '')) : null;
}

export function isURLAnalyticsSafe(url) {
    return _.isString(url) && !hasCapitalisedCity(url) && !hasTrailingSlash(url);
}
