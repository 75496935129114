import {
    PAGE,
    PERSISTED_STATE_SEARCH_LISTINGS,
    PERSISTED_STATE_SEARCH_FILTERS,
    PERSISTED_STATE_SEARCH_PAGE, PERSISTED_FIRM_REVIEW_FILTERS,
} from '../__constants__';
import { APP_REDIRECT_STATUS, SET_APP_REDIRECT_STATUS } from './types';
import { isURLAnalyticsSafe } from '../utils/analyticsUtilities';
import * as analytics from '../analytics';


export const routeChangeListener = (pathname, pageId, redirectStatus) => (dispatch) => {
    handleAnalyticsPageView(pathname);

    removePersistedSearchFilters(pageId);

    removePersistedReviewFilters(pageId);

    // Once the 301 meta tag has been rendered and the router has redirected to the new page
    // Clear the redirect status (which will remove the 301 meta tag)
    if (redirectStatus === APP_REDIRECT_STATUS.REDIRECTED) {
        dispatch(clearAppRedirectStatus());
    }
};

export const setAppRedirectStatus = (status) => {
    return {
        type: SET_APP_REDIRECT_STATUS,
        payload: status,
    };
};

const clearAppRedirectStatus = () => {
    return {
        type: SET_APP_REDIRECT_STATUS,
        payload: null,
    };
};

function handleAnalyticsPageView(pathname) {
    if (isURLAnalyticsSafe(pathname)) {
        analytics.pageView(pathname);
    }
}

function removePersistedSearchFilters(pageId) {
    const pagesToPersistFilters = [
        PAGE.PROFILE,
        PAGE.FIRM,
        PAGE.SEARCH,
    ];

    if (!pagesToPersistFilters.includes(pageId)) {
        sessionStorage.removeItem(PERSISTED_STATE_SEARCH_PAGE);
        sessionStorage.removeItem(PERSISTED_STATE_SEARCH_FILTERS);
        sessionStorage.removeItem(PERSISTED_STATE_SEARCH_LISTINGS);
    }
}

function removePersistedReviewFilters(pageId) {
    if (pageId !== PAGE.FIRM) {
        sessionStorage.removeItem(PERSISTED_FIRM_REVIEW_FILTERS);
    }
}
