export const PROFILE_CHECK_PASS = 'pass';
export const PROFILE_CHECK_FAIL = 'fail';
export const PROFILE_CHECK_PENDING = 'pending';

export const PROFILE_CHECK_TYPES = [
    PROFILE_CHECK_PASS,
    PROFILE_CHECK_FAIL,
    PROFILE_CHECK_PENDING,
];

export const FIRM_TYPES = {
    ADVISER: 'adviser',
    LEGAL_ADVISER :'legal_adviser',
    COACH: 'coach'
}
