import {HIDE_NOTIFICATION_MESSAGE, SHOW_NOTIFICATION_MESSAGE, SET_SHOW_NOTIFICATION,} from '../actions/types';

const INITIAL_STATE = {
    showNotification: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_NOTIFICATION_MESSAGE:
            return { showNotification: true};
        case HIDE_NOTIFICATION_MESSAGE:
            return {...INITIAL_STATE};
        default:
            return state;
    }
};
