export const LOCATION = {
    my_office: 'Office',
    your_home: 'Your Home',
    video_call: 'Video Call',
    other: 'Other',
};

export const REVIEW_TYPE = {
    FIRST_IMPRESSION: 'first_impression',
    REVIEW: 'review',
};

export const CHECK_STATUS_NOT_FULLY_VERIFIED = 'not_fully_verified';

export const QUALIFICATION_CATEGORY = {
    ADVANCED: {
        TYPE: 'advanced',
        LABEL: 'advanced',
    }
};

export const CHART_LABEL = {
    financial_adviser_client_level_financial_expertise: {
        name: `Clients' level of financial expertise`,
        categories: {
            financial_expertise_low: `Low`,
            financial_expertise_average: `Average`,
            financial_expertise_high: `High`,
            financial_expertise_very_high: `Very high`
        },
    },
    client_household_income: {
        name: `Household income`,
        categories: {
            household_income_0_30k: `£0-30K`,
            household_income_30k_50k: `£30-50K`,
            household_income_50k_100k: `£50-100k`,
            household_income_100k_200k: `£100-200K`,
            household_income_200k_0: `£200K+`,
        },
    },
    financial_adviser_mortgage_size: {
        name: `Mortgage value`,
        categories: {
            mortgage_size_0_100k: `£0-100K`,
            mortgage_size_100k_250k: `£100-250K`,
            mortgage_size_250k_500k: `£250-500K`,
            mortgage_size_500k_750k: `£500-750K`,
            mortgage_size_750k_0: `£750K+`,
        },
    },
    client_savings_and_investments: {
        name: `Savings`,
        categories: {
            savings_and_investments_0_50k: `£0-50K`,
            savings_and_investments_50k_100k: `£50-100K`,
            savings_and_investments_100k_300k: `£100-300K`,
            savings_and_investments_300k_500k: `£300-500K`,
            savings_and_investments_500k_0: `£500K+`,
        },
    },
    client_age: {
        name: `Age group`,
        categories: {
            client_age_18_34: `18-34`,
            client_age_35_44: `35-44`,
            client_age_45_54: `45-54`,
            client_age_55_64: `55-64`,
            client_age_65_0: `65+`,
        },
    },
    client_first_became_client: {
        name: `Time as client`,
        categories: {
            time_as_client_0_1: `< 2 yrs`,
            time_as_client_2_5: `2-5 yrs`,
            time_as_client_6_9: `6-9 yrs`,
            time_as_client_10_0: `10+ yrs`,
        },
    },
};

export const PROFILE_TABS = {
    ABOUT: 'about',
    LOCATION: 'location',
    FIRM: 'firm',
    SERVICES: 'services',
    FEES: 'fees',
    QUALIFICATIONS: 'education',
    EXPERIENCE: 'experience',
    AWARDS: 'awards',
};

export const PERMALINK_TOWN_UNKNOWN = "town-unknown";
