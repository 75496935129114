import { API_KEYS } from './__constants__/apiKeys';
import { isProductionEnv, shouldGARun } from './utils/utilities';
import _ from 'lodash';

// eslint-disable-next-line
const GTM = key => {
    return {
        activate: function() {
            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
                'gtm.start': new Date().getTime(),
                'event': 'gtm.js'
            });

            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = '//www.googletagmanager.com/gtm.js?id=' + key + '&l=dataLayer';
            const first = document.getElementsByTagName('script')[0];
            first.parentNode.insertBefore(script, first);
        },
        track: function (action, options) {
            const { category, label, value } = options;

            if (isProductionEnv()) {
                return window.dataLayer.push({ category, event: action, label, value });
            }
        },
        pageView: function(pathname) {
            if (isProductionEnv() && pathname) {
                return window.dataLayer.push({
                    event: 'Loaded a Page',
                    path: pathname,
                    url: window.location.href,
                    title: document.title,
                    referrer: document.referrer
                });
            }

            return console.log(`URL visited: ${pathname}`);
        },
    };
};


const gTag = key => {
    return {
        track: function (action, options) {
            const { category, label, value, professionalId, firmId, networkId } = options;

            if (!isProductionEnv()) {
                console.log(
                    `Gtag activated\n` +
                    (category ? `Category: ${category}\n` : '') +
                    (action ? `Action: ${action}\n` : '') +
                    (label ? `Label: ${label}\n` : '') +
                    (value ? `Value: ${JSON.stringify(value)}\n` : '') +
                    (professionalId ? `Professional id: ${JSON.stringify(professionalId)}\n` : '') + 
                    (networkId ? `Network id: ${JSON.stringify(networkId)}\n` : '') + 
                    (firmId ? `Firm id: ${JSON.stringify(firmId)}\n` : '')
                );
            }

            if (isProductionEnv() || shouldGARun()) {
                gtag('event', action, {
                    'event_category': category,
                    'event_label': label,
                    'value': value,
                    ...(professionalId && {'professional_id': professionalId}),
                    ...(firmId && {'firm_id': firmId}),
                    ...(networkId && {'network_id': networkId}),
                });
            }
        },
        pageView: function(pathname) {
            if ((isProductionEnv() || shouldGARun()) && pathname) {
                return window.gtag('config', key, { page_path: pathname });
            }
        },
        getField: function(fieldName, callback) {
            if (!isProductionEnv()) {
                console.log(
                    `Gtag activated\n` +
                    `Get field: ${fieldName}\n`
                );
            }

            if (isProductionEnv() || shouldGARun()) {
                gtag('get', key, fieldName,  callback);
            }
        }
    };
}

const googleAnalytics = key => {
    return {
        track: function (action, options) {
            const { category, label, value } = options;

            if (!isProductionEnv()) {
                console.log(
                    `Analytics activated\n` +
                    (category ? `Category: ${category}\n` : '') +
                    (action ? `Action: ${action}\n` : '') +
                    (label ? `Label: ${label}\n` : '') +
                    (value ? `Value: ${JSON.stringify(value)}\n` : '')
                );
            }

            if (isProductionEnv() || shouldGARun()) {
                window.ga('send', 'event', category, action, label, value);
            }
        },
        pageView: function(pathname) {
            if ((isProductionEnv() || shouldGARun()) && pathname) {
                return window.ga('send', 'pageview', pathname);
            }
        },
    };
};

const integrations = [
    googleAnalytics(API_KEYS.GOOGLE_ANALYTICS_KEY),
    // GTM(API_KEYS.GOOGLE_TAG_MANAGER_KEY),
    gTag(API_KEYS.GTAG_KEY)
];

function isBot() {
    return /bot|crawler|spider|crawling/i.test(_.get(window, 'navigator.userAgent', ''));
}

export function track(action, options) {
    if (isBot()) return;
    integrations.forEach(integration => integration.track(action, options));
}

export function pageView(pathname) {
    if (isBot()) return;
    integrations.forEach(integration => integration.pageView(pathname));
}

export function getField(fieldName, callback) {
    if (isBot()) return;
    integrations[1].getField(fieldName, callback);
}