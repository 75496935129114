export const CONTACT = {
    EMAIL: 'customer_service@vouchedfor.co.uk',
    PHONE_NUMBER: '0203 111 0580',
    ACCOUNTS_TEAM_NUMBER: '0203 111 0583',
};

export function capitalizeFirstLetter(str) {
    return str[0].toUpperCase() + str.slice(1);
}

export const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
};

export const getFirmChangedFirmReviewMessage = (professionalType) => {
    return (professionalType === 'adviser' ?
            'This review is for an adviser who has since left this firm.'
            : 'This review is for a financial coach who has since left this firm.'
    );
};

export const adviserProfileFirmReviewMessage = () => {
    return 'This adviser has some reviews that were left while they were employed at a different firm to the one they are at now.';
};

export const TRANSFERRED_CLIENT_REVIEW_GKA = 'elevation_transferred_client_review';