import {
    RESET_PROFILE_DATA,
    SET_PROFILE_LOCATION,
    SET_PROFILE_QUALIFICATIONS,
    SET_PROFILE_FEE_SUMMARY,
    SET_FEE_REPORT_DATA,
    SET_PROFILE_DATA,
    SET_PROFILE_SERVICES,
    SET_PROFILE_REVIEW_STATISTICS,
    SET_PROFILE_CHECKS,
    SET_PROFILE_PROMOTIONS,
    SET_PROFILE_FEES,
    SET_PROFILE_JOBS,
    SET_PROFILE_AWARDS
} from '../actions/types';


const INITIAL_STATE = {
    meetingLocations: null,
    qualifications: null,
    feeSummary: null,
    feeReportData: null,
    profileData: {
        professionalId: null,
        serviceIds: [],
        parentServiceIds: [],
        profile: {
            data: null,
        },
        reviewStatistics: {
            data: null,
        },
        professionalChecks: {
            data: null,
        },
        promotions: {
            data: null,
            attributeIds: [],
        },
        fees: {
            data: null,
        },
        jobs: {
            data: [],
        },
        awards: {
            data: [],
        },
        initialReviews: {
            data: [],
            totalCount: 0
        },
        reviewFilters: {
            data: []
        }
    }
};

export default (state = INITIAL_STATE, action) => {
    const newProfile = {...state.profileData}

    switch (action.type) {
        case RESET_PROFILE_DATA:
            return {...INITIAL_STATE};
        case SET_PROFILE_LOCATION:
            return {...state, meetingLocations: action.payload};
        case SET_PROFILE_QUALIFICATIONS:
            return {...state, qualifications: action.payload};
        case SET_PROFILE_FEE_SUMMARY:
            return {...state, feeSummary: action.payload};
        case SET_FEE_REPORT_DATA:
            return {...state, feeReportData: action.payload};
        case SET_PROFILE_DATA:
            return {...state, profileData: {...state.profileData, ...action.payload}};
        case SET_PROFILE_SERVICES:
            newProfile.serviceIds = {...action.payload.serviceIds}
            newProfile.parentServiceIds = {...action.payload.parentServiceIds}
            return {...state, profileData: {...newProfile}};
        case SET_PROFILE_REVIEW_STATISTICS:
            newProfile.reviewStatistics = {...action.payload}
            return {...state, profileData: {...newProfile}};
        case SET_PROFILE_CHECKS:
            newProfile.professionalChecks = {...action.payload}
            return {...state, profileData: {...newProfile}};
        case SET_PROFILE_PROMOTIONS:
            newProfile.promotions = {...action.payload}
            return {...state, profileData: {...newProfile}};
        case SET_PROFILE_FEES:
            newProfile.fees = {...action.payload}
            return {...state, profileData: {...newProfile}};
        case SET_PROFILE_JOBS:
            newProfile.jobs = {...action.payload}
            return {...state, profileData: {...newProfile}};
        case SET_PROFILE_AWARDS:
            newProfile.awards = {...action.payload}
            return {...state, profileData: {...newProfile}};
        default:
            return state;
    }
};
