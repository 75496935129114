import { combineReducers } from 'redux';
import searchReducer from './searchReducer';
import profileReducer from './profileReducer';
import redirectReducer from './redirectReducer';
import notificationReducer from "./notificationReducer";

export default combineReducers({
    search: searchReducer,
    profile: profileReducer,
    redirect: redirectReducer,
    notification: notificationReducer,
});
