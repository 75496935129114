export const LIVE_SITE_URL = 'https://www.vouchedfor.co.uk/';
export const ABOUT_URL = 'https://about.vouchedfor.co.uk/';
export const ASSET_URL = 'https://assets.vouchedfor.co.uk/img/';
export const DEPT_ADVICE_SERVICE = 'https://www.moneyadviceservice.org.uk/en/articles/what-is-a-debt-management-plan';
export const DEPT_SAVING_EXPERT = 'https://www.moneysavingexpert.com/loans/debt-help-plan/';
export const S3_URL = 'https://s3.eu-west-1.amazonaws.com/professionals.vouchedfor.co.uk/';
export const GUIDE_URL = 'https://guide.vouchedfor.co.uk/2020FinancialAdvisers/TheTimes/HowToQualify/?utm_source=homepage';
export const ACCOUNTANTS_GUIDE_URL = 'https://guide.vouchedfor.co.uk/2019-accountants';
export const PENSIONS_ADVISORY_SERVICE = 'https://www.pensionsadvisoryservice.org.uk';
export const TRUST_PILOT_URL = 'https://uk.trustpilot.com/review/www.vouchedfor.co.uk';

export const SOCIAL_MEDIA_URLS = {
    TWITTER: 'https://twitter.com/VouchedFor/',
    FACEBOOK: 'https://www.facebook.com/VouchedFor/',
    LINKEDIN: 'https://www.linkedin.com/company/vouchedfor/',
};

export const COMPANY_URLS = {
    EMAIL_SUPPORT: 'mailto:consumer_support@vouchedfor.co.uk',
    VF_CONTACT_PAGE_URL: `${ABOUT_URL}about/contact`
};
