import {
    HAS_SEARCH_RESULTS,
    SET_LOCAL_TOWNS,
    SET_SEARCH_RESULTS_LOADING,
    RESET_SEARCH_PAGE_STATE,
} from '../actions/types';

const INITIAL_STATE = {
    loading: false,
    hasSearchResults: true,
    localTowns: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case HAS_SEARCH_RESULTS:
            return {...state, hasSearchResults: action.payload};
        case SET_LOCAL_TOWNS:
            return {...state, localTowns: action.payload};
        case SET_SEARCH_RESULTS_LOADING:
            return {...state, loading: action.payload};
        case RESET_SEARCH_PAGE_STATE:
            return {...INITIAL_STATE};
        default:
            return state;
    }
};
