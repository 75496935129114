export const HAS_SEARCH_RESULTS = 'HAS_SEARCH_RESULTS';
export const SET_LOCAL_TOWNS = 'SET_LOCAL_TOWNS';
export const SET_SEARCH_RESULTS_LOADING = 'SET_SEARCH_RESULTS_LOADING';
export const RESET_SEARCH_PAGE_STATE = 'RESET_SEARCH_PAGE_STATE';

export const RESET_PROFILE_DATA = 'RESET_PROFILE_DATA';
export const SET_PROFILE_LOCATION = 'SET_PROFILE_LOCATION';
export const SET_PROFILE_QUALIFICATIONS = 'SET_PROFILE_QUALIFICATIONS';
export const SET_PROFILE_FEE_SUMMARY = 'SET_PROFILE_FEE_SUMMARY';
export const SET_FEE_REPORT_DATA = "SET_FEE_REPORT_DATA";

export const SET_APP_REDIRECT_STATUS = 'SET_APP_REDIRECT_STATUS';
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';
export const SET_PROFILE_SERVICES = 'SET_PROFILE_SERVICES';
export const SET_PROFILE_REVIEW_STATISTICS = 'SET_PROFILE_REVIEW_STATISTICS';
export const SET_PROFILE_CHECKS = 'SET_PROFILE_CHECKS';
export const SET_PROFILE_PROMOTIONS = 'SET_PROFILE_PROMOTIONS';
export const SET_PROFILE_FEES = 'SET_PROFILE_FEES';
export const SET_PROFILE_JOBS = 'SET_PROFILE_JOBS';
export const SET_PROFILE_AWARDS = 'SET_PROFILE_AWARDS';

export const APP_REDIRECT_STATUS = {
    INITIALISED: 'INITIALISED',
    REDIRECTED: 'REDIRECTED',
};


export const SHOW_NOTIFICATION_MESSAGE = 'SET_NOTIFICATION_MESSAGE';
export const HIDE_NOTIFICATION_MESSAGE = 'HIDE_NOTIFICATION_MESSAGE';
